<script setup lang="ts">
import { zip } from 'lodash';
import ZipCode from '~/components/ZipCode.vue';

const router = useRouter()
type Response = {
    "county": string[],
    "route_to": string
}
const response = ref<Response | null>(null)

const requestData = async (zipcode: string) => {
    const url =
        import.meta.env.VITE_WEBSITE_API_URL +
        '/counties'
    const res = await axios.post<Response>(url, {
        zip_code: zipcode
    })

    response.value = res.data
}

const { copy } = useClipboard()

const copyToClipboard = (text: string) => {
    copy(text)
}

</script>
<template>
    <Layout :hide-header="true" footer="2024"
        class="flex flex-col min-h-[100vh] p-6 gap-6 md:gap-12 font-dm items-center relative">

        <ZipCode @submit-zip-code="requestData" />
        <div v-if="response" class="flex flex-col justify-start w-full p-4 gap-6 max-w-lg ">
            <div class="flex flex-col gap-4 flex-grow justify-between">
                <h2 class="text-lg font-semibold">Counties</h2>
                <div class="text-base font-normal flex-grow justify-between pl-4 pb-2 flex border-b border-b-gray-200 items-center"
                    v-for="county in response.county">
                    <span>{{ county }}</span>
                    <button
                        class="border border-rounded bg-green-400 hover:bg-green-600 active:bg-green-600 text-white p-2"
                        type="button" @click="copyToClipboard(county)">Copy</button>
                </div>
            </div>
            <div class="">
                <h2 class="text-lg font-semibold">Route To</h2>
                <div class="text-base font-normal flex-grow justify-between flex pl-4 flex gap-6">
                    <span>{{ response.route_to }}</span>
                    <button
                        class="border border-rounded bg-green-400 hover:bg-green-600 active:bg-green-600 text-white p-2"
                        type="button" @click="copyToClipboard(response.route_to)">Copy</button>
                </div>
            </div>
        </div>
    </Layout>
</template>

<route lang="json">{
    "meta": {
        "brands": [
            "bh",
            "med10",
            "m10",
            "boomer"
        ],
        "robots": "noindex",
        "benefits": [
            "otc"
        ]
    }
}</route>
